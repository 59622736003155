import React from "react";
import Compo from "@smartly-city/compo";
import { useCurrentUserPermissionsSuspenseQuery } from "src/graphql/__generated__/CurrentUserPermissions";
import { useGetCurrentUserFromSecuritySuspenseQuery } from "src/graphql/__generated__/SecurityGetCurrentUser";
import { useGetCurrentUserPermittedAreasFromSecuritySuspenseQuery } from "src/graphql/__generated__/SecurityGetCurrentUserPermittedAreas";
import { AppContext } from "./AppContext";
import type { AppContextState } from "./AppContext";
import type {
  Security_UserReadModel,
  Security_AreaReadModel,
} from "src/graphql/types";

export interface AppProviderProps {
  children?: React.ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = (props) => {
  const [area, setArea] = React.useState<Security_AreaReadModel>();

  const currentUser = useFetchCurrentUser();
  const currentUserAreas = useFetchCurrentUserAreas();
  const currentUserPermissions = useFetchCurrentUserPermissions();

  React.useEffect(() => {
    const savedAreaId = localStorage.getItem("area");
    if (area?.id && savedAreaId !== area.id) {
      localStorage.setItem("area", area.id);
    }
  }, [area]);

  React.useEffect(() => {
    const savedAreaId = localStorage.getItem("area");
    const savedArea = currentUserAreas.find((area) => area.id === savedAreaId);
    setArea(savedArea ?? currentUserAreas[0] ?? undefined);
  }, [currentUserAreas]);

  if (!area) return <Compo.Spinner />;

  if (!currentUser) {
    window.location.href = "/logout";
    return <div />;
  }

  const state: AppContextState = {
    area,
    setArea,
    user: {
      ...currentUser,
      id: currentUser.id,
      permissions: currentUserPermissions,
      permittedAreas: currentUserAreas,
    },
  };

  return (
    <AppContext.Provider value={state}>{props.children}</AppContext.Provider>
  );
};

const useFetchCurrentUser = (): Security_UserReadModel | undefined => {
  const currentUserResult = useGetCurrentUserFromSecuritySuspenseQuery();
  return currentUserResult.data?.Security_getCurrentUser
    ?.user as Security_UserReadModel;
};

const useFetchCurrentUserAreas = (): Security_AreaReadModel[] => {
  const currentUserAreasResult =
    useGetCurrentUserPermittedAreasFromSecuritySuspenseQuery();
  return React.useMemo(
    () =>
      (currentUserAreasResult.data?.Security_getCurrentUserPermittedAreas
        ?.areas as Security_AreaReadModel[]) ?? [],
    [currentUserAreasResult]
  );
};

const useFetchCurrentUserPermissions = (): string[] => {
  const currentUserPermissionsResult = useCurrentUserPermissionsSuspenseQuery();
  return React.useMemo(
    () => currentUserPermissionsResult.data?.currentUserPermissions ?? [],
    [currentUserPermissionsResult]
  );
};

export default AppProvider;
