import type * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserPermissionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserPermissionsQuery = { __typename?: 'Query', currentUserPermissions: Array<string> };


export const CurrentUserPermissionsDocument = gql`
    query currentUserPermissions {
  currentUserPermissions
}
    `;

/**
 * __useCurrentUserPermissionsQuery__
 *
 * To run a query within a React component, call `useCurrentUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserPermissionsQuery, CurrentUserPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserPermissionsQuery, CurrentUserPermissionsQueryVariables>(CurrentUserPermissionsDocument, options);
      }
export function useCurrentUserPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserPermissionsQuery, CurrentUserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserPermissionsQuery, CurrentUserPermissionsQueryVariables>(CurrentUserPermissionsDocument, options);
        }
export function useCurrentUserPermissionsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CurrentUserPermissionsQuery, CurrentUserPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserPermissionsQuery, CurrentUserPermissionsQueryVariables>(CurrentUserPermissionsDocument, options);
        }
export type CurrentUserPermissionsQueryHookResult = ReturnType<typeof useCurrentUserPermissionsQuery>;
export type CurrentUserPermissionsLazyQueryHookResult = ReturnType<typeof useCurrentUserPermissionsLazyQuery>;
export type CurrentUserPermissionsSuspenseQueryHookResult = ReturnType<typeof useCurrentUserPermissionsSuspenseQuery>;
export type CurrentUserPermissionsQueryResult = Apollo.QueryResult<CurrentUserPermissionsQuery, CurrentUserPermissionsQueryVariables>;